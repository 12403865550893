import(/* webpackMode: "eager" */ "/home/deline/components/organisms/appbar/desktop.tsx");
;
import(/* webpackMode: "eager" */ "/home/deline/components/organisms/appbar/mobile.tsx");
;
import(/* webpackMode: "eager" */ "/home/deline/components/organisms/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/deline/node_modules/@mui/material/Toolbar/Toolbar.js");
;
import(/* webpackMode: "eager" */ "/home/deline/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/deline/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
